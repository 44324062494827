<script setup>
import { computed } from 'vue';

const props = defineProps({
    class: {
        type: String,
        default: '',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    type: {
        type: String,
        default: 'submit',
    },
});

const classes = computed(() =>
    `text-white bg-primary px-4 py-2 rounded-md border border-primary hover:bg-primary-dark ${props.class} ${props.disabled ? 'opacity-50 cursor-not-allowed' : ''}`
);
</script>

<template>
    <button
        :class="classes"
        :disabled="props.disabled"
        :type="props.type"
    >
        <slot />
    </button>
</template>


